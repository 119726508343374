import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

function NewsletterPage({ location }) {
    return (
        <Layout location={location}>
            <Seo title="Newsletter Page Title" description="Newsletter page description" />
            <main>
                <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
                    <div className="py-16">
                        <section className="py-12 text-center max-w-screen-sm mx-auto">
                            <iframe
                                src="https://uxchoice.substack.com/embed"
                                style={{ border: "1px solid #EEE", background: "white", margin: "auto", width: "420px", height: "320px", maxWidth: "100%" }}
                                frameBorder={0}
                                scrolling="no"
                            />

                        </section>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default NewsletterPage
